import React, { Component } from "react";
import { connect } from "react-redux";
import { Dropdown } from "react-bootstrap";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { ThreeDotsDropdown } from "../../atoms/ThreeDotsDropdown/ThreeDotsDropdown";
import { attemptLoginAction } from "../../../actions/authActions";
import logoImg from "../../../img/zg-milestones.png";
import FullPageLoader from "../../organisms/FullPageLoader/FullPageLoader";
import store from "../../../store";

class Login extends Component {
    static propTypes = {
        lastAttemptFailed: PropTypes.func.isRequired,
    };

    constructor() {
        super();
        this.state = {
            active: "login",
            username: "",
            password: "",
            showPassword: false,
            SignupSchema: Yup.object().shape({
                username: Yup.string().required("Username field is required"),
                password: Yup.string().required("Password field is required"),
            }),
        };
    }

    login(ev) {
        ev.preventDefault();
        const { username, password } = this.state;
        const { attemptLogin } = this.props;
        attemptLogin(username, password);
    }

    showHidePassword = () => {
        const { showPassword } = this.state;
        if (showPassword) {
            this.setState({ showPassword: false });
        } else {
            this.setState({ showPassword: true });
        }
    };

    render() {
        const { active, showPassword, SignupSchema } = this.state;
        const { lastAttemptFailed } = this.props;
        const dropdown = (
            <div className="actions actions--inverse login__block__actions">
                <Dropdown>
                    <Dropdown.Toggle
                        as={ThreeDotsDropdown}
                        className="dropdown"
                    />
                    <Dropdown.Menu>
                        {active === "login" ? (
                            <Dropdown.Item
                                onClick={() =>
                                    this.setState({ active: "forgot_password" })
                                }
                            >
                                Forgot password
                            </Dropdown.Item>
                        ) : (
                            <Dropdown.Item
                                onClick={() =>
                                    this.setState({ active: "login" })
                                }
                            >
                                Back to login
                            </Dropdown.Item>
                        )}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        );
        return (
            <div className="login">
                <FullPageLoader />

                <div
                    className={
                        active === "login"
                            ? "login__block active"
                            : "login__block"
                    }
                >
                    <Formik
                        initialValues={{
                            ...this.state,
                        }}
                        validationSchema={SignupSchema}
                        onSubmit={(val, { resetForm, setSubmitting }) => {
                            setSubmitting(false);
                            resetForm();
                            store.dispatch(
                                attemptLoginAction(val.username, val.password)
                            );
                        }}
                    >
                        {({
                            handleBlur,
                            handleChange,
                            values,
                            errors,
                            touched,
                        }) => (
                            <Form>
                                <div className="login__block__body">
                                    <div className="img__logo">
                                        <img src={logoImg} alt="logo" />
                                    </div>
                                    <div className="login-error-block">
                                        {lastAttemptFailed && (
                                            <span className="error-item-text">
                                                Username or password is wrong.
                                                Please try again or contact the
                                                support.
                                            </span>
                                        )}
                                    </div>

                                    {errors.username && touched.username ? (
                                        <div className="login-error-block">
                                            <span className="error-item-text">
                                                {errors.username}
                                            </span>
                                        </div>
                                    ) : undefined}

                                    <div className="form-group  mb-3">
                                        <input
                                            name="username"
                                            id="usernameId"
                                            type="text"
                                            className="form-control mb-2 mr-sm-2 form-control-custom"
                                            placeholder="USER NAME"
                                            value={values.username}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            style={{
                                                borderColor:
                                                    errors.username &&
                                                    touched.username
                                                        ? "red"
                                                        : "",
                                            }}
                                        />
                                    </div>

                                    {errors.password && touched.password ? (
                                        <div className="login-error-block">
                                            <span className="error-item-text">
                                                {errors.password}
                                            </span>
                                        </div>
                                    ) : undefined}

                                    <div className="form-group password-form-group">
                                        <input
                                            name="password"
                                            id="passwordId"
                                            type={
                                                showPassword
                                                    ? "text"
                                                    : "password"
                                            }
                                            className="form-control form-control-custom"
                                            placeholder="PASSWORD"
                                            value={values.password}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            style={{
                                                borderColor:
                                                    errors.password &&
                                                    touched.password
                                                        ? "red"
                                                        : "",
                                            }}
                                        />
                                        <span
                                            className="eye-icon"
                                            onClick={() =>
                                                this.showHidePassword()
                                            }
                                            role="presentation"
                                        >
                                            {showPassword ? (
                                                <i
                                                    className="zwicon-eye-slash"
                                                    aria-hidden="true"
                                                />
                                            ) : (
                                                <i
                                                    className="zwicon-eye"
                                                    aria-hidden="true"
                                                />
                                            )}
                                        </span>
                                    </div>

                                    <button
                                        className="btn btn-outline-light login__btn__block login__btn__hover"
                                        type="submit"
                                    >
                                        LOGIN
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>

                <div
                    className={
                        active === "forgot_password"
                            ? "login__block active"
                            : "login__block"
                    }
                >
                    <div className="login__block__header">
                        <i className="zwicon-user-circle" />
                        Forgot Password?
                        {dropdown}
                    </div>

                    <div className="login__block__body">
                        <p className="mb-5">
                            Lorem ipsum dolor fringilla enim feugiat commodo sed
                            ac lacus.
                        </p>

                        <div className="form-group">
                            <input
                                type="text"
                                className="form-control text-center"
                                placeholder="Email Address"
                            />
                        </div>

                        <a
                            href="index.html"
                            className="btn btn-theme btn--icon"
                        >
                            <i className="zwicon-checkmark" />
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => ({
    lastAttemptFailed: state.auth.lastAttemptFailed,
});
const mapDispatchToProps = (dispatch) => ({
    attemptLogin: (username, password) =>
        dispatch(attemptLoginAction(username, password)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
