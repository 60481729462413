import React, {Component} from 'react';
import VentureMilestonePhoto from "../VentureMilestonePhoto/VentureMilestonePhoto";
import TextLimiter from "../../atoms/TextLimiter/TextLimiter";
import MilestoneProgress from "../MilestoneProgress/MilestoneProgress";
import "./_styles.scss";
import MilestoneReassignModal from "../MilestoneReassignModal/MilestoneReassignModal";
import CommentsModal from "../CommentsModal/CommentsModal";
import MilestoneDeleteButton from "../MilestoneDeleteButton/MilestoneDeleteButton";
import {ligaDateFormat, shortDateFormat} from "../../../helpers/dateFormat";
import MilestoneCompletionModal from "../MilestoneCompletionModal/MilestoneCompletionModal";
import MilestoneReassignMobileButton from "../MilestoneReassignMobileButton/MilestoneReassignMobileButton";
import MilestoneCommentsMobileButton from "../MilestoneCommentsMobileButton/MilestoneCommentsMobileButton";

import EditMilestoneModal from "../EditMilestoneModal/EditMilestoneModal";

export default class MilestoneItem extends Component{
    constructor(props) {
        super(props);
        const {milestone} = this.props;
        this.state = {
            milestone,
            reassignModalOpened: false,
            commentsModalOpened: false,
            completionModalOpened: false,
            editModalOpened: false,
        };
        this.clickCount = 0;
        this.singleClickTimer = '';
    }

    resetMilestone(milestone)
    {
        this.setState({
            milestone
        });
    }

    handleClicks(milestone)
    {
        this.clickCount++;
        if (this.clickCount === 1) {
            this.singleClickTimer = setTimeout(function() {
                this.clickCount = 0;
            }.bind(this), 300);
        } else if (this.clickCount === 2) {
            clearTimeout(this.singleClickTimer);
            this.clickCount = 0;
            this.setState({editModalOpened: true})
        }
    }

    render() {
        const {milestone, reassignModalOpened, commentsModalOpened, completionModalOpened, editModalOpened} = this.state;
        return <div 
            className={'row single-milestone' + (milestone.deleted_at !== null && milestone.deleted_at !== undefined ? ' single-milestone-hidden' : '')}
            onClick={() => this.handleClicks(milestone)} 
        >
            <MilestoneReassignModal
                show={reassignModalOpened}
                close={() => this.setState({reassignModalOpened: false})}
                milestone={milestone}
                resetMilestone={(milestone) => this.resetMilestone(milestone)}
            />
            <CommentsModal
                show={commentsModalOpened}
                close={() => this.setState({commentsModalOpened: false})}
                milestone={milestone}
            />
            <MilestoneCompletionModal
                show={completionModalOpened}
                close={() => this.setState({completionModalOpened: false})}
                milestone={milestone}
                resetMilestone={(milestone) => this.resetMilestone(milestone)}
            />
            <EditMilestoneModal
                show={editModalOpened}
                close={() => this.setState({editModalOpened: false})}
                milestone={milestone}
                resetMilestone={(milestone) => this.resetMilestone(milestone)}
            />
            <div className="single-milestone-col col-12 col-md-6 col-xl-1">
                <VentureMilestonePhoto
                    milestone={milestone}
                    resetMilestone={(milestone) => this.resetMilestone(milestone)}
                />
            </div>
            <div className="single-milestone-col col-8 col-md-6 col-xl-2 ">
                <span className="category-name">{milestone.milestone_category.name}</span>
            </div>
            <div className="single-milestone-col col-12 col-md-6 col-xl-3">
                <TextLimiter text={milestone.description} />
            </div>
            <div className="single-milestone-col single-milestone-due-date col-12 col-md-6 col-xl-1 col-6">
                <span className="due-date">
                    {shortDateFormat(milestone.due_date)}
                </span>
            </div>
            <div className="single-milestone-col single-milestone-progress-col col-12 col-md-10 col-xl-4 ">
                <MilestoneProgress
                    resetMilestone={(milestone) => this.resetMilestone(milestone)}
                    openReassignBar={() => this.setState({reassignModalOpened: true})}
                    openCommentsModal={() => this.setState({commentsModalOpened: true})}
                    openCompletionModal={() => this.setState({completionModalOpened: true})}
                    milestone={milestone}
                />
            </div>
            <div className="single-milestone-col single-milestone-actions col-12 col-md-2 col-xl-1">
                <MilestoneReassignMobileButton
                    openReassignBar={() => this.setState({reassignModalOpened: true})}
                    milestone={milestone}
                />
                <MilestoneCommentsMobileButton
                    openCommentsModal={() => this.setState({commentsModalOpened: true})}
                    milestone={milestone}
                />
                <MilestoneDeleteButton
                    milestone={milestone}
                    resetMilestone={(milestone) => this.resetMilestone(milestone)}
                />
            </div>
        </div>;
    }
}
