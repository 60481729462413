import React, {Component} from "react";
import {Modal, OverlayTrigger, Tooltip} from "react-bootstrap";
import Flatpickr from "react-flatpickr";
import {connect} from 'react-redux';
import './_style.scss';
import Select from 'react-select'
import PropTypes from "prop-types";
import {getMilestoneFilterOptions} from "../../../helpers/selectOptions";
import PageLoaderSpinner from "../../atoms/PageLoaderSpinner/PageLoderSpinner";
import {fetchCategoriesAction} from "../../../actions/categoriesActions";
import {fetchUsersAction} from "../../../actions/usersActions";
import {fetchVenturesAction} from "../../../actions/venturesActions";
import {adjustForTimezone} from "../../../helpers/dateFormat";
import Api from "../../../services/ApiService";

class EditMilestoneModal extends Component {
    static propTypes = {
        close: PropTypes.func.isRequired,
        show: PropTypes.bool.isRequired,
        didLoadCategories: PropTypes.bool.isRequired,
        didLoadUsers: PropTypes.bool.isRequired,
        didLoadVentures: PropTypes.bool.isRequired,
        categoriesList: PropTypes.array,
        usersList: PropTypes.array,
        venturesList: PropTypes.array,
    };

    constructor(props) {
        super(props);
        const { milestone } = this.props;
        this.state = {
            id: milestone.id ?? null,
            dueDate: [new Date(milestone.due_date)] ?? null,
            description: milestone.description ?? null,
            selectedMilestoneCategory: milestone.milestone_category_id ? { label: milestone.milestone_category.name, value: milestone.milestone_category.id } : null,
            selectedVenture: milestone.venture_id ? { label: milestone.venture.name, value: milestone.venture.id } : null,
            selectedAssignedTo: milestone.assigned_to_id ? { label: milestone.assigned_to.last_name + ',' + milestone.assigned_to.first_name, value: milestone.assigned_to.id } : null,
            error: '',
            blocked: false,
            blocker: 'Please fill all fields',
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.show && !prevProps.show) {
            this.loadPage();
        }
    }

    updateBlockers()
    {
        const {
            selectedMilestoneCategory,
            selectedVenture,
            selectedAssignedTo,
            dueDate,
            description,
            error,
        } = this.state;

        if (description.length < 3) {
            this.setState({blocked: true, blocker: 'Description too short'})
        } else if (selectedAssignedTo === null) {
            this.setState({blocked: true, blocker: 'Please select user'})
        } else if (selectedMilestoneCategory === null) {
            this.setState({blocked: true, blocker: 'Please select milestone category'})
        } else if (selectedVenture === null) {
            this.setState({blocked: true, blocker: 'Please select venture'})
        } else if (!dueDate || dueDate.length === 0 || dueDate[0].length < 5) {
            this.setState({blocked: true, blocker: 'Please enter due date'})
        } else {
            this.setState({blocked: false});
        }

    }

    close() {
        this.props.close();
    }

    async loadPage()
    {
        const {
            fetchCategories,
            fetchVentures,
            fetchUsers,
            milestone
        } = this.props;

        fetchCategories();
        fetchVentures();
        fetchUsers();
    }

    inputUpdate(fieldName, value) {
        const newState = {error: ''};
        newState[fieldName] = value;
        this.setState(
            newState,
            () => this.updateBlockers()
        );
    }

    async update() {
        const {resetMilestone} = this.props;
        try {
            const {
                id,
                dueDate,
                description,
                selectedMilestoneCategory,
                selectedVenture,
                selectedAssignedTo
            } = this.state

            const {status, data} = await Api.patch(`milestones/${id}`, {
                due_date: dueDate[0],
                description: description,
                milestone_category_id: selectedMilestoneCategory.value,
                venture_id: selectedVenture.value,
                assigned_to_id: selectedAssignedTo.value,
            });
            if (status === 200) {
                let milestone = data;
                resetMilestone(milestone);
                this.close();
            }
        } catch (error){
            
        }
    }

    render() {
        const {
            selectedMilestoneCategory,
            selectedVenture,
            selectedAssignedTo,
            dueDate,
            description,
            blocked,
            blocker,
            error,
            isPending,
        } = this.state;
        const {
            show,
            milestone,
            didLoadCategories,
            didLoadVentures,
            didLoadUsers,
            venturesList,
            categoriesList,
            usersList,
        } = this.props;
        const {venturesOptions, categoriesOptions, usersOptions } = getMilestoneFilterOptions(
            didLoadVentures,
            venturesList,
            didLoadCategories,
            categoriesList,
            didLoadUsers,
            usersList,
        );

        let submitButton = blocked
            ? <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={
                    <Tooltip id="validation-blocker-tooltip">
                        <div>
                            {blocker}
                        </div>
                    </Tooltip>
                }
            >
                <div>
                    <button onClick={(ev) => ev.preventDefault()}><i className="zwicon-checkmark" /></button>
                </div>
            </OverlayTrigger>
            : <button onClick={() => this.update()}><i className="zwicon-checkmark" /></button>;
        if (isPending) {
            submitButton = <PageLoaderSpinner />
        }

        return (
            <Modal
                dialogClassName="edit-milestone-modal"
                show={show}
                onHide={() => this.close()}
                centered
                enforceFocus={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>UPDATE MILESTONE</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                        <div className="row">
                            <div className="col-md-6 col-sm-12 remove-padding">
                                <Select
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    placeholder="Venture"
                                    options={venturesOptions}
                                    value={selectedVenture}
                                    onChange={(selection) => this.inputUpdate('selectedVenture', selection)}
                                />
                                <Select
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    placeholder="Owner"
                                    options={usersOptions}
                                    value={selectedAssignedTo}
                                    onChange={(selection) => this.inputUpdate('selectedAssignedTo', selection)}
                                />
                            </div>
                            <div className="col-md-6 col-sm-12  remove-padding" >
                                <Select
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    placeholder="Category"
                                    options={categoriesOptions}
                                    value={selectedMilestoneCategory}
                                    onChange={(selection) => this.inputUpdate('selectedMilestoneCategory', selection)}
                                />
                                <Flatpickr
                                    className="milestone-due-date-picker"
                                    options={{dateFormat: 'd.m.Y'}}
                                    static={true}
                                    placeholder="Due date"
                                    defaultValue={new Date(dueDate).toISOString()}
                                    date={dueDate}
                                    onChange={(date) => this.inputUpdate('dueDate', [adjustForTimezone(date)])}
                                />
                            </div>
                        </div>

                    <br />

                    <br />
                    <textarea
                        placeholder="Enter milestone description"
                        onChange={(ev) => this.inputUpdate('description', ev.target.value)}
                    >
                        {description}
                    </textarea>
                    {submitButton}
                </Modal.Body>
            </Modal>
        )
    }
}

const mapPropsToState = state => ({
    didLoadCategories: state.categories.didLoadCategories,
    categoriesList: state.categories.categoriesList,
    didLoadVentures: state.ventures.didLoadVentures,
    venturesList: state.ventures.venturesList,
    didLoadUsers: state.users.didLoadUsers,
    usersList: state.users.usersList,
});

const mapDispatchToProps = dispatch => ({
    fetchCategories:  () => dispatch(fetchCategoriesAction()),
    fetchVentures:  () => dispatch(fetchVenturesAction()),
    fetchUsers:  () => dispatch(fetchUsersAction()),
});

export default connect(mapPropsToState, mapDispatchToProps)(EditMilestoneModal);
